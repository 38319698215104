/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker"
export { wrapRootElement } from "./src/apollo/wrap-root-element";
CheckWebpFeature();


export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/property-for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-to-rent/)) {
      return false;
    }

    if (location.pathname.match(/sell\/sold-gallery/)) {
      return false;
    }
    if (location.pathname.match(/landlords\/let-gallery/)) {
      return false;
    }
  
    return true;
};


const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    //yomdel chat script, will work on live site and allowed domains
   // addScript("https://clients.yomdel.com/tools/chat_script.js?url=" + window.location.href)
  }
}