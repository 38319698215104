import React from 'react';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { ApolloProvider } from '@apollo/client';
import { GlobalProvider } from '../context/GlobalContext';
import { client } from './client';

TimeAgo.addDefaultLocale(en)

export const wrapRootElement = ({ element }) => {
    return(
        <GlobalProvider>
            <ApolloProvider client={client}>{element}</ApolloProvider>
        </GlobalProvider>
    )
}
